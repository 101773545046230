<template>
  <article class="scheme">
    <div class="img-wrapper" v-if="url && canShow">
      <button class="scheme__button" @click="handle($event)">
        <span v-html="getIcon('wide')" class="icon"></span>
      </button>
      <img class="scheme__img" :src="url" alt="" width="100%">
    </div>
  </article>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  name: 'ConstructionScheme',
  mixins: [IconMixin],
  props: {
    url: String,
    canShow: Boolean
  },
  methods: {
    handle() {
      this.$emit('zoomScheme', { action: 'zoom', url: this.url })
    }
  }
}
</script>

<style lang="sass">
.scheme
  +media((width: (0: 100%, 768: rem(200), 1024: 260px)))
  +media((margin-top: (0: rem(20), 768: 0)))
  .img-wrapper
    display: flex
    align-items: center
    justify-content: center
    +media((height: (0: rem(200), 1024: 260px)))
    margin: 0 0 rem(20)
    position: relative
    border-radius: $block-radius
    border: 1px solid $border-color
    background: $white
    img
      height: 100%
      object-fit: contain
  &__button
    display: flex
    align-items: center
    padding: 0
    margin: 0
    position: absolute
    top: 0
    right: 0
    transform: translate(rem(-8), rem(8))
    border: none
    background: none
    cursor: pointer
    .icon
      width: rem(20)
      height: rem(20)
</style>
