<template>
  <section class="navigation__slider" ref="pane">
    <div class="navigation__slider-container">
      <nav class="nav">
        <ul class="nav__list">
            <li v-for="(step, index) of steps"
                :key="index"
                class="nav__item"
                :class="{ 'nav__item--active': index === currentStepIndex }"
            >
              <h5 class="nav__title">
                <a class="nav__link" @click="handler(index)">
                  <span v-if="!isValid(index)" class="nav__step-number">{{ index + 1 }}</span>
                  <span v-else v-html="getIcon('ok')" class="nav__valid-icon"></span>
                  <span class="nav__text">{{ step.title }}</span>
                </a>
              </h5>
            </li>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  mixins: [IconMixin],
  props: {
    steps: Array
  },
  computed: {
    currentStepIndex() {
      return this.steps.findIndex(step => step.validation.isCurrentStep)
    },
    activeSector() {
      return this.$store.state.activeSector
    }
  },
  methods: {
    isValid(index) {
      return this.steps[index].validation.valid
    },
    handler(index) {
      const isTargetStepValid = this.isValid(index)
      const canPassToNextStep =
        this.isValid(this.currentStepIndex) && (index - this.currentStepIndex === 1)

      if (this.isValid(index) || isTargetStepValid || canPassToNextStep) {
        this.$store.commit('UPDATE_SECTOR', {
          index: this.activeSector,
          property: 'currentStep',
          value: index
        })

        if (index === stepNumbers.result) {
          this.$router.push({ name: 'Result' })
        }

        if (this.currentStepIndex === stepNumbers.result && !this.$route.params.id) {
          this.$router.push({ path: `/${this.$i18n.locale}/wizard/${this.activeSector}` })
        }
      }
    }
  }
}
</script>

<style lang="sass">
.navigation__slider
  background: $white
  border-radius: $block-radius
  .navigation__slider-container
    padding: 0
.nav
  &__list
    display: flex
    margin: rem(20) 0
    +media((padding: (0: rem(15), 992: rem(20))))
    +media((justify-content: (0: flex-start, 911: space-between)))
    +media((gap: (0: 20px, 911: 0)))
  &__item, &__link
    display: flex
    align-items: center
  &__item
    &:not(.nav__item--active)
      .nav__text
        +media((display: (0: none, 911: flex)))
    &--active
      .nav__step-number
        color: $white
        background: $main-color
  &__link
    cursor: pointer
  &__title
    margin: 0
    @extend %16
  &__step-number, &__valid-icon
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    margin: 0 rem(8) 0 0
    border-radius: 50%
    +media((width: (0: rem(26), 420: rem(40))))
    +media((height: (0: rem(26), 420: rem(40))))
  &__step-number
    @extend %20
    color: $placeholder
    background: $bg-grey
  &__valid-icon
    border: 1px solid $border-color
    background: $white
    svg
      +media((transform: (0: scale(0.6), 420: none)))
      path
        stroke: $main-color
</style>
