<template>
  <article class="questions">
    <h5 class="questions__link-title">{{ this.$t('message.aside.title') }}</h5>
    <a class="questions__link" href="/">
      {{ this.$t('message.aside.linkTitle') }}
    </a>
  </article>
</template>

<script>
export default {
  name: 'Questions'
}
</script>

<style lang="sass">
.questions
  display: flex
  flex-flow: column nowrap
  align-items: flex-start
  padding: rem(20)
  background: $bg-grey
  border-radius: $block-radius
  +media((width: (0: 100%, 768: 200px, 1024: 260px)))
  +media((margin-top: (0: rem(20), 768: 0)))
  &__link-title
    margin: 0 0 rem(16)
    font-weight: 600
  &__link
    color: $main-color
    font-weight: 600
</style>
