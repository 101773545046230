<script>
export default {
  name: 'IconMixin',
  methods: {
    getIcon(name) {
      return this.$t(`message.icons.${name}`)
    }
  }
}
</script>

<style scoped>

</style>
