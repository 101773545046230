<template>
  <button class="simple-btn" :class="classes" @click.stop="handler" :disabled="isDisabled">
    <slot name="icon" v-if="icon">
      <span class="icon" v-html="getIcon(icon)"></span>
    </slot>
    <slot></slot>
  </button>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    color: String,
    padding: String,
    isDisabled: {
      type: Boolean,
      defaults: false
    },
    icon: String
  },
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  },
  computed: {
    classes() {
      return {
        [`simple-btn--${this.color}`]: this.color,
        [`simple-btn--padding-${this.padding}`]: this.padding
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.simple-btn
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: rem(16) 0
  font-family: $main-font
  box-shadow: none
  outline: none
  border: 1px solid transparent
  border-radius: $block-radius
  transition: .5s
  cursor: pointer
  font-style: normal
  @extend %14
  font-weight: 600
  +media((min-width: (0: auto, 768: rem(174))))
  .icon
    display: flex
    align-items: center
    width: rem(24)
    height: rem(24)
    margin-right: rem(8)
  &--main
    @extend %light
    &:hover
      @extend %dark
  &--white
    @extend %dark
    border-color: $border-color
    &:hover
      @extend %light
  &--red
    color: $main-color
    border-color: $main-color
    background: none
    &:hover
      color: $main-text
      border-color: $main-text
  &--gray
    color: $main-text
    border-color: $border-color
    background: none
    &:hover
      @extend %light
  &--transparent-gray
    color: $white
    background: #414E60
    opacity: 0.5
    border: none
    border-radius: rem(5)
  &--padding-10
    padding: rem(11) rem(10)
  &--padding-30
    padding: rem(16) rem(30)
  &--padding-40
    padding: rem(16) rem(40)
  &:disabled
    cursor: no-drop
    background: $blocked
    &:hover
      color: inherit
      background: $blocked
      border-color: transparent
</style>
