<template>
  <transition name="modal">
    <div class="modal__overlay" v-if="isModalOpen" @click.self="close">
      <div class="modal__wrapper"
           :class="{
              'modal__wrapper--details': modalData,
              'modal__wrapper--without-materials': modalData && !config.materials
           }"
      >
        <div class="modal__body">
          <button @click="btnClicked('close')" class="close" >
            <span v-html="getIcon('close')"></span>
          </button>
          <h2 v-if="config.title" class="modal__title">{{ config.title }}</h2>
          <p v-if="config.text" class="modal__text">{{ config.text }}</p>
          <p class="modal__image" v-if="src || config.src">
            <img :src="src || config.src" alt="" width="100%"/>
          </p>
          <article v-if="modalData && config.materials" class="modal__details">
            <ul class="details__list">
              <li class="details__item" v-for="(field, index) of getIndexes(config)" :key="index">
                <span class="details__item-title">{{ field.title }}</span>
                <span class="details__item-value">{{ field.value }}</span>
              </li>
            </ul>
            <h6 class="materials__title">{{ $t('message.modal.details.materials-title') }}</h6>
            <ul class="materials__list">
              <li class="materials__item" v-for="material of config.materials" :key="material.id">
                <span class="materials__item-title">{{ material.title }}</span>
                <span class="materials__item-value">{{ material.value }} {{ material.unit }}</span>
              </li>
            </ul>
          </article>
          <div class="modal__buttons" v-if="config.buttons">
            <template v-for="button in config.buttons">
              <SimpleButton
                :color="button.color"
                padding="40"
                @btnClicked="btnClicked(button.action, modalData)"
                :key="button.title">
                {{ button.title }}
              </SimpleButton>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import SimpleButton from '@/components/dump/Simple-button'

export default {
  components: { SimpleButton },
  mixins: [IconMixin],
  props: {
    isModalOpen: Boolean,
    action: String,
    src: String,
    modalData: Object
  },
  methods: {
    btnClicked(action, value) {
      this.$emit('action', { action, value })
    },
    getIndexes(config) {
      return config.indexes.filter((item) => item.index !== null)
    }
  },
  computed: {
    config() {
      const configs = {
        delete: {
          title: this.$t('message.modal.removing.title'),
          text: this.$t('message.modal.removing.text'),
          buttons: {
            cancel: {
              title: this.$t('message.buttons.cancel'),
              color: 'red',
              action: 'cancel'
            },
            delete: {
              title: this.$t('message.buttons.delete'),
              color: 'main',
              action: 'delete'
            }
          }
        },
        zoom: {
          title: '',
          text: '',
          buttons: null
        },
        details: {
          title: this.modalData?.title,
          text: this.modalData?.description,
          src: this.modalData?.image,
          indexes: [
            {
              title: this.$t('message.modal.details.indexes.rw'),
              value: `Rw = ${this.modalData?.rw}`,
              index: this.modalData?.rw
            },
            {
              title: this.$t('message.modal.details.indexes.lnw'),
              value: `Lnw = ${this.modalData?.lnw}`,
              index: this.modalData?.lnw
            }
          ],
          materials: this.modalData?.materials,
          buttons: {
            select: {
              title: this.$t('message.buttons.select'),
              color: 'main',
              action: 'select'
            }
          }
        }
      }

      return configs[this.action]
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  &__overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 4
    background: rgba(0, 0, 0, 0.50)
    backdrop-filter: blur(10px)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
  &__wrapper
    position: relative
    border-radius: $block-radius
    background: $white
    +media((width: (0: 100%, 480: rem(500))))
    +media((padding: (0: rem(15), 768: rem(40))))
    &--details
      +media((width: (0: 100%, 950: rem(890))))
      +media((padding: (0: rem(30), 950: rem(50))))
      +media((margin: (0: 0 rem(30), 950: 0)))
      &.modal__wrapper--without-materials
        +media((width: (0: 100%, 700: rem(600))))
        .modal__body
          +media((grid-template-areas: (0: 'title title' 'description description' 'scheme scheme' 'details details' 'buttons buttons')))
          img
            +media((width: (0: 50%, 762: 90%)))
      .modal__body
        display: grid
        grid-column-gap: rem(30)
        grid-row-gap: rem(10)
        +media((grid-template-columns: (0: repeat(2, 1fr), 950: rem(470) 1fr)))
        +media((grid-template-areas: (0: 'title title' 'description description' 'scheme scheme' 'details details' 'buttons buttons', 560: 'title title' 'description description' 'scheme details' 'scheme details' 'buttons buttons', 700: 'title title' 'description description' 'scheme details' 'scheme buttons')))
      .modal__title
        grid-area: title
        margin: 0 0 rem(15)
        +media((font-size: (0: rem(20), 560: rem(24), 768: rem(34))))
        font-weight: 800
        line-height: 1.2
      .modal__text
        grid-area: description
        +media((margin: (0: 0, 560: 0 0 rem(32))))
        @extend %18
        font-weight: 400
      .modal__image
        grid-area: scheme
        display: flex
        align-items: center
        justify-content: center
        padding: rem(32) rem(85)
        margin: 0
        border: 1px solid $border-color
        border-radius: $block-radius
        +media((padding: (0: rem(10), 762: rem(20) rem(35), 950: rem(32) rem(85))))
        img
          +media((width: (0: 50%, 560: 100%, 762: 90%)))
      .modal__details
        grid-area: details
        .materials, .details
          &__title
            margin: 0
          &__item, .details__item
            display: flex
            align-items: center
            justify-content: space-between
            margin: rem(8) 0 0
            @extend %14
            font-weight: 600
            text-align: left
            &-title
              font-weight: 400
            &-value
              flex-shrink: 0
              align-self: flex-end
              margin-left: rem(8)
        .details
          &__item
            flex-wrap: wrap
            margin: 0 0 rem(24)
            &-title
              width: 100%
              margin: 0 0 rem(8)
            &-value
              margin: 0
      .modal__buttons
        grid-area: buttons
        align-self: self-end
        margin: rem(25) 0 0
        .simple-btn
          width: 100%
          height: rem(60)
          @extend %16
  &__title
    margin: 0 0 rem(15)
  &__text
    margin: 0 0 rem(30)
    @extend %16
    line-height: 1.3
    text-align: left
  &__buttons
    display: flex
    gap: rem(20)
    .simple-btn
      width: calc(50% - 10px)
  &__body
    .close
      @extend .clear-btn
      height: rem(16)
      width: rem(16)
      position: absolute
      right: rem(20)
      top: rem(20)
      z-index: 4
</style>
