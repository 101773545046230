<script>
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  name: 'ModalMixin',
  data: () => ({
    isModalOpen: false,
    modalAction: null,
    modalData: null,
    src: null
  }),
  methods: {
    onZoom(evt) {
      this.src = evt.url
      this.modalAction = 'zoom'
      this.isModalOpen = !this.isModalOpen
    },
    handleModalAction(evt) {
      switch (evt.action) {
        case 'delete':
          if (this.sectors.length > 1) {
            this.$store.commit('DELETE_SECTOR', { index: this.deleteIndex })
            this.navigate({ action: 'switch', index: this.deleteIndex === 0 ? this.deleteIndex : this.deleteIndex - 1 })
          }
          break
        case 'select':
          this.$store.commit('UPDATE_SECTOR', {
            index: this.sectorIndex,
            property: this.sector.currentStep === stepNumbers.systemFilter ? 'system' : 'construction',
            value: evt.value
          })
          break
        case 'close':
          this.$store.commit('UPDATE_MODAL', { property: 'selectedSystem', value: null })
          break
        default:
          break
      }
      this.isModalOpen = !this.isModalOpen
      this.modalData = null
      this.modalAction = null
      this.src = null
    }
  }
}
</script>
