<template>
  <section class="wizard-footer">
    <SimpleButton
      v-if="step !== stepNumbers.constructionTypes"
      class="wizard-footer__button wizard-footer__button--back"
      :color="isResultPage ? 'gray' : 'white'"
      padding="40"
      @btnClicked="navigate(actions.back)"
    >
      {{ $t('message.buttons.back') }}
    </SimpleButton>
    <SimpleButton
      v-if="isResultPage"
      class="wizard-footer__button wizard-footer__button--next"
      color="main"
      padding="30"
      @btnClicked="navigate(actions.toStart)"
    >
      {{ $t('message.buttons.addCalculation') }}
    </SimpleButton>
    <SimpleButton
      v-else
      class="wizard-footer__button wizard-footer__button--next"
      color="main"
      padding="30"
      :is-disabled="!isValidStep"
      @btnClicked="navigate(actions.next)"
    >
      {{ isValidStep ? $t('message.buttons.next') : $t('message.buttons.blocked') }}
    </SimpleButton>
  </section>
</template>

<script>
import SimpleButton from '@/components/dump/Simple-button'
import { stepNumbers } from '@/utils/stepNumbers'
import { actions } from '@/utils/utils'

export default {
  name: 'NavigationFooter',
  components: { SimpleButton },
  props: ['validation', 'isResultPage'],
  data: () => ({
    actions,
    stepNumbers
  }),
  methods: {
    navigate(evt) {
      this.$emit('navigate', { action: evt })
      if (this.step === this.stepNumbers.constructionParams && evt === this.actions.next) {
        this.$router.push({ name: 'Result' })
      }
    }
  },
  computed: {
    isValidStep() {
      return this.validation[this.step].valid
    },
    step() {
      return this.validation.findIndex(step => step.isCurrentStep)
    }
  }
}
</script>

<style scoped lang="sass">
.wizard-footer
  grid-area: footer
  align-self: end
  display: flex
  padding: rem(30) 0 0
  margin-top: rem(30)
  border-top: 1px solid $border-color
  &__button
    max-height: rem(58)
    @extend %16
    font-weight: 600
    &--next
      margin-left: auto
</style>
