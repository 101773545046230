<script>
import { mapActions, mapGetters } from 'vuex'
import { stepNumbers } from '@/utils/stepNumbers'
import { CalcTypes } from '@/utils/types'
import { actions } from '@/utils/utils'
import { calc, sendStatistic, statisticLabels } from '@/utils/sendStatistic'

export default {
  name: 'NavigationMixin',
  methods: {
    ...mapActions({
      sendMetrics: 'sendMetrics'
    }),
    checkStepValidation() {
      Object.entries(this.steps).forEach(([key, value]) => {
        if (key > this.currentStepNumber) {
          value.validation.valid = false
          if (value?.reset) value.reset()
        }
      })
    },
    navigate(evt) {
      switch (evt.action) {
        case actions.next:
          this.updateSector('currentStep', this.currentStepNumber + 1)
          break
        case actions.back:
          this.updateSector('currentStep', this.currentStepNumber - 1)
          if (!this.$route.params.id) {
            this.$router.push({ path: `/${this.$i18n.locale}/wizard/${this.activeSector}` })
          }
          break
        case actions.toStart:
          this.$store.commit('ADD_SECTORS')
          this.$store.commit('UPDATE_ACTIVE_SECTOR', this.sectors.length - 1)
          this.$router.push({ path: `/${this.$i18n.locale}/wizard/${this.sectors.length - 1}` })
          break
        case actions.switchSector:
          this.$store.commit('UPDATE_ACTIVE_SECTOR', evt.index)
          const targetSectorStep = this.sectors[evt.index].currentStep
          this.$router.push(
            { path: targetSectorStep === stepNumbers.result
              ? `/${this.$i18n.locale}/result`
              : `/${this.$i18n.locale}/wizard/${evt.index}`
            }
          )
          break
        case actions.editSector:
          this.$store.commit('UPDATE_ACTIVE_SECTOR', evt.index)
          this.updateSector('currentStep', 0)
          this.$router.push({ path: `/${this.$i18n.locale}/wizard/${evt.index}` }
          )
      }

      this.saveSessionInfo()
    },
    updateSector(property, value, subproperty = null) {
      this.$store.commit('UPDATE_SECTOR', { index: this.sectorIndex, property, value, subproperty })
    },
    sendStatistic(step) {
      const stepEvents = window.dataLayer
        .filter(l => l.event ? l.event.includes(`${calc}-step`) || l.event.includes(`${calc}-final`) : false)
        .reverse()

      if (stepEvents.length) {
        const isRepeat = stepEvents[0].event.includes(step)
        const isFromResultPage = stepEvents[0].event.includes('final')

        if (!isRepeat || isFromResultPage) sendStatistic(statisticLabels.steps[step])
      }
    },
    saveSessionInfo() {
      sessionStorage.setItem('sectors', JSON.stringify(this.sectors))
      sessionStorage.setItem('activeSector', JSON.stringify(this.$store.state.activeSector))
    }
  },
  computed: {
    ...mapGetters({
      getSector: 'getSector'
    }),
    currentStepNumber() {
      return this.sector.currentStep
    },
    sector() {
      return this.getSector(this.sectorIndex)
    },
    sectorIndex() {
      return this.$store.state.activeSector
    },
    sectors() {
      return this.$store.getters.getSectors()
    },
    isResultPage() {
      return this.currentStepNumber === stepNumbers.result
    },
    isThirdStep() {
      return this.sector.currentStep === stepNumbers.constructionParams
    },
    isTabsVisible() {
      return this.currentStepNumber === stepNumbers.constructionParams ||
        this.currentStepNumber === stepNumbers.systemFilter
    },
    isHotelsSelected() {
      return this.sector?.constructionType.buildingType === this.$t('message.constructionTypes.buildings.types.hotel')
    },
    validation() {
      return Object.entries(this.steps).map(([key, value]) => value.validation)
    },
    titles() {
      return Object.entries(this.steps).map(([key, value]) => value.titles)
    },
    navigationConfig() {
      return Object.entries(this.steps)
        .map(([key, value]) => ({ title: value.titles.nav, validation: value.validation }))
    },
    steps() {
      return {
        [stepNumbers.constructionTypes]: {
          titles: {
            main: this.$t('message.wizard.titles.constructionTypes'),
            nav: this.$t('message.nav.step1')
          },
          validation: {
            valid: !Object.entries(this.sector?.constructionType).find(([key, value]) => {
              return this.isHotelsSelected ? value === null : key !== 'rating' && value === null
            }),
            isCurrentStep: stepNumbers.constructionTypes === this.currentStepNumber
          }
        },
        [stepNumbers.systemFilter]: {
          titles: {
            main: this.$t('message.wizard.titles.systemFilter.title'),
            subtitle: this.$t('message.wizard.titles.systemFilter.subtitle'),
            nav: this.$t('message.nav.step2')
          },
          validation: {
            valid: this.sector?.system !== null,
            isCurrentStep: stepNumbers.systemFilter === this.currentStepNumber
          },
          reset: () => {
            this.updateSector('system', null)
            this.updateSector('algorithm', CalcTypes.lite)
          }
        },
        [stepNumbers.constructionParams]: {
          titles: {
            main: this.$t('message.wizard.titles.constructionParams'),
            nav: this.$t('message.nav.step3')
          },
          validation: {
            valid: this.sector.isCalculated,
            isCurrentStep: stepNumbers.constructionParams === this.currentStepNumber
          },
          reset: () => {
            ['base', 'screed', 'facing', 'isolation', 'optionalSystems', 'indexes'].forEach((property) => {
              if (!this.sector[property]) return
              const keys = Array.isArray(this.sector[property]) ? null : Object.keys(this.sector[property])

              keys
                ? keys.forEach((subproperty) => this.updateSector(property, null, subproperty))
                : this.updateSector(property, null, null)
            })
            this.updateSector('errors', null)
            this.updateSector('image', null)
            this.updateSector('construction', null)
            this.updateSector('filling', null)
            this.updateSector('isCalculated', null)
            this.$store.commit('UPDATE_MODAL', { property: 'selectedSystem', value: null })
          }
        },
        [stepNumbers.result]: {
          titles: {
            main: '',
            nav: this.$t('message.nav.step4')
          },
          validation: {
            valid: this.sector.isCalculated,
            isCurrentStep: stepNumbers.result === this.currentStepNumber
          }
        }
      }
    }
  }
}
</script>

<style scoped></style>
